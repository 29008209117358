<template>
    <div>
        <div class="row page-title align-items-center">
            <div class="col-md-3 col-xl-6">
                <h4 class="mb-1 mt-0">Create New Event</h4>
            </div>
            <div class="col-md-9 col-xl-6 text-md-right">
                <div class="mt-4 mt-md-0">
                    <a href="https://docs.evacone.com.au/#/mobile/eventspaceguide" class="btn btn-dark mb-3 mb-sm-0"
                        target="_blank">View Guidelines</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-8">
                <div class="card">
                    <div class="card-body">

                        <div v-if="selectedLocation">
                            <h4 class="header-title mt-0">Location: {{ selectedLocation.name }}</h4>
                            <p class="sub-header">
                                You are creating an advertisement for an event at the <b>{{ selectedLocation.name }}</b>
                                - {{ selectedLocation.addressLine1 }} {{ selectedLocation.addressLine2 }} {{
                                selectedLocation.suburb }} {{ selectedLocation.state }} {{ selectedLocation.postcode }}
                            </p>
                        </div>

                        <form-wizard color="#5369f8" @on-complete="onComplete">

                            <tab-content title="Event" :before-change="validateStep1">
                                <ValidationObserver ref="step1" v-slot="{ handleSubmit }">
                                    <form autocomplete="off" @submit.prevent="handleSubmit(save)">

                                        <b-form-group label="Select Company/Tenant" label-for="Company" description="">
                                            <ValidationProvider v-slot="{ errors }"
                                                :rules="{ required: true, max: 500 }" :custom-messages="customMessages">
                                                <b-form-select v-model="selectedCompany" name="Company" class="mb-2">
                                                    <option text="Please Select" value="">Please Select</option>
                                                    <option v-for="(item, index) in companyList" :key="index"
                                                        :value="item" :text="item.name">{{ item.name }}</option>
                                                </b-form-select>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <b-form-group label="Select Location" label-for="Location" description="">
                                            <ValidationProvider v-slot="{ errors }"
                                                :rules="{ required: true, max: 500 }" :custom-messages="customMessages">
                                                <b-form-select v-model="selectedLocation" name="Location" class="mb-2">
                                                    <option text="Please Select" value="">Please Select</option>
                                                    <option v-for="(item, index) in locationList" :key="index"
                                                        :value="item" :text="item.name">{{ item.name }} ({{ item.city }}
                                                        {{ item.state }} {{ item.postcode }}) </option>
                                                </b-form-select>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <b-form-group label="Event Title" label-for="eventTitle" description=""
                                            class="mb-4">
                                            <ValidationProvider class="w-full" name="eventTitle" v-slot="{ errors }"
                                                :rules="{ regex: /^[a-zA-Z0-9 -|,]+$/, required: true, max: 100 }"
                                                :custom-messages="customMessages">
                                                <b-form-input id="eventTitle" name="eventTitle"
                                                    v-model="advertisement.title"
                                                    placeholder="name of the event"></b-form-input>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <b-form-group label="Event Description" label-for="eventDescription"
                                            description="describe your event upto 8000 characters">
                                            <ValidationProvider class="w-full" name="eventDescription"
                                                v-slot="{ errors }" :rules="{ required: true, max: 8000 }"
                                                :custom-messages="customMessages">
                                                <b-form-textarea id="eventDescription"
                                                    v-model="advertisement.description" size="lg" rows="3" max-rows="25"
                                                    placeholder="describe"></b-form-textarea>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <b-form-group label="" label-for="EventDateType" class="mb-4">
                                            <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                <b-form-radio v-model="advertisement.eventType"
                                                    :aria-describedby="ariaDescribedby" name="some-radios"
                                                    value="Ongoing">Ongoing Promotion</b-form-radio>
                                                <b-form-radio v-model="advertisement.eventType"
                                                    :aria-describedby="ariaDescribedby" name="some-radios"
                                                    value="EventDate">Event on a Date</b-form-radio>
                                            </b-form-group>
                                        </b-form-group>

                                        <b-form-group label="Describe Event Date & Time" label-for="eventDateText"
                                            v-if="advertisement.eventType === 'Ongoing'">
                                            <ValidationProvider class="w-full" name="eventDateText" v-slot="{ errors }"
                                                :rules="{ regex: /^[a-zA-Z0-9 -|,]+$/, required: true, max: 100 }"
                                                :custom-messages="customMessages">
                                                <b-form-input id="eventDateText" name="eventDateText"
                                                    v-model="advertisement.eventDateText"
                                                    placeholder="e.g. everyday from 6 pm"></b-form-input>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <div class="row">
                                            <div class="col-md-4 col-xl-4">
                                                <b-form-group label="Event Date" label-for="eventDate"
                                                    v-if="advertisement.eventType === 'EventDate'">
                                                    <ValidationProvider class="w-full" :rules="{ required: true }"
                                                        v-slot="{ errors }" :custom-messages="customMessages"
                                                        name="visitstartDate">
                                                        <flat-pickr v-model="advertisement.eventDate"
                                                            :config="startDateTimeConfig" class="w-full form-control"
                                                            name="visitstartDate" />
                                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-form-group>

                                            </div>
                                            <div class="col-md-4 col-xl-4">
                                                <b-form-group label="Event Time" label-for="eventTime"
                                                    v-if="advertisement.eventType === 'EventDate'">
                                                    <ValidationProvider class="w-full" :rules="{ required: true }"
                                                        v-slot="{ errors }" :custom-messages="customMessages"
                                                        name="eventTime">
                                                        <flat-pickr v-model="advertisement.eventTime"
                                                            :config="eventTimeConfig" class="w-full form-control"
                                                            name="eventTime" />
                                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-form-group>
                                            </div>
                                        </div>

                                        <b-form-group label="Tags" label-for="eventCategory">
                                            <ValidationProvider class="w-full" name="categoryselect"
                                                :rules="{ required: true }" v-slot="{ errors }"
                                                :custom-messages="customMessages">
                                                <b-form-tags name="categoryselect"
                                                    v-model="advertisement.tags"></b-form-tags>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <b-form-group label="Link To Web" label-for="linktoweb"
                                            v-if="productCode !== 'Basic'">
                                            <ValidationProvider name="linktoweb"
                                                :rules="{ required: true, regex: /^(https:\/\/www\.|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
                                                v-slot="{ errors }"
                                                :custom-messages="{ regex: 'Enter value in the format of a url.', required: 'Required' }">
                                                <b-form-input id="linktoweb" name="linktoweb"
                                                    v-model="advertisement.linkToWeb"
                                                    placeholder="https://...."></b-form-input>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                    </form>
                                </ValidationObserver>

                            </tab-content>
                            <tab-content title="Upload Media" :before-change="validateStep2">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="vue-editor mt-0 mb-1">Upload Image or Video</h4>
                                        <p class="text-muted font-13 m-b-30">Please upload an image or video for your
                                            event.</p>
                                        <vue-dropzone id="dropzone" name="mydropzone"
                                            @vdropzone-error="fileUploadFailed" @vdropzone-success="fileUploaded"
                                            @vdropzone-max-files-reached="maxfilesreached"
                                            @vdropzone-sending="sendingEvent" ref="myVueDropzone" :duplicateCheck="true"
                                            @vdropzone-thumbnail="thumbnailCreated" :options="dropzoneOptions"
                                            @vdropzone-file-added="fileAdded">
                                        </vue-dropzone>
                                    </div>
                                </div>
                            </tab-content>

                            <tab-content title="Discounts" :before-change="validateStepDiscount"
                                v-if="productCode !== 'Basic'">

                                <ValidationObserver ref="stepDiscount" v-slot="{ handleSubmit }">
                                    <form autocomplete="off" @submit.prevent="handleSubmit(save)">
                                        <b-form-group label="Discount Code" label-for="discountcode" description=""
                                            class="mb-4">
                                            <ValidationProvider class="w-full" name="discountcode" v-slot="{ errors }"
                                                :rules="{ regex: /^[a-zA-Z0-9]+$/, required: false, max: 15 }"
                                                :custom-messages="customMessages">
                                                <b-form-input id="discountcode" name="discountcode"
                                                    v-model="advertisement.discountCoupon"
                                                    placeholder="Disount Coupon Code"></b-form-input>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>
                                        <b-form-group label="Discount Percentage (1% to 100%)"
                                            label-for="discountPercentage" description="" class="mb-4">
                                            <ValidationProvider class="w-full" name="discountPercentage"
                                                v-slot="{ errors }"
                                                :rules="{ regex: /^[0-9.]+$/, required: false, max: 5, max_value: 100, min_value: 0 }"
                                                :custom-messages="customMessages">
                                                <b-form-input id="discountPercentage" name="discountPercentage"
                                                    v-model="advertisement.discountPercentage"
                                                    placeholder="Discount Percentage"></b-form-input>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>
                                        <b-form-group label="Discount Description" label-for="discountDescription"
                                            description="" class="mb-4">
                                            <ValidationProvider class="w-full" name="discountDescription"
                                                v-slot="{ errors }"
                                                :rules="{ regex: /^[a-zA-Z0-9 #@$%,./]+$/, required: false, max: 100 }"
                                                :custom-messages="customMessages">
                                                <b-form-input id="discountDescription" name="discountDescription"
                                                    v-model="advertisement.discountDescription"
                                                    placeholder="Disount Description"></b-form-input>
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>
                                    </form>
                                </ValidationObserver>
                            </tab-content>

                            <tab-content title="Advertise" :before-change="validateStep3">
                                <ValidationObserver ref="step3" v-slot="{ handleSubmit }">
                                    <form autocomplete="off" @submit.prevent="handleSubmit(save)">
                                        <b-form-group label="Advertise Start Date" label-for="startdate"
                                            description="date to start advertisement">
                                            <ValidationProvider class="w-full" :rules="{ required: true }"
                                                v-slot="{ errors }" :custom-messages="customMessages"
                                                name="advertiseStartDate">
                                                <flat-pickr v-model="advertisement.advertiseStartDate"
                                                    :config="advertiseStartDateTimeConfig" class="w-full"
                                                    name="advertiseStartDate" />
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <b-form-group label="Advertise End Date" label-for="enddate"
                                            description="date to end advertisement, ads will stop showing at the end of the date selected.">
                                            <ValidationProvider class="w-full" :rules="{ required: true }"
                                                v-slot="{ errors }" :custom-messages="customMessages"
                                                name="advertiseEndDate">
                                                <flat-pickr v-model="advertisement.advertiseEndDate"
                                                    :config="advertiseEndDateTimeConfig" class="w-full"
                                                    name="advertiseEndDate" />
                                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-form-group>

                                        <b-form-group label="" label-for="targetAudience" class="mb-4">
                                            <b-form-group label="Target Audience" name="targetAudience"
                                                v-slot="{ ariaDescribedby }">
                                                <b-form-radio v-model="advertisement.ageDemographic"
                                                    :aria-describedby="ariaDescribedby" name="some-radios" value="0">All
                                                    Age groups (inc family and kids)</b-form-radio>
                                                <b-form-radio v-model="advertisement.ageDemographic"
                                                    :aria-describedby="ariaDescribedby" name="some-radios"
                                                    value="13">Over 13 years of age</b-form-radio>
                                                <b-form-radio v-model="advertisement.ageDemographic"
                                                    :aria-describedby="ariaDescribedby" name="some-radios"
                                                    value="18">Over 18 years of age (Adults Only)</b-form-radio>
                                            </b-form-group>
                                        </b-form-group>

                                    </form>
                                </ValidationObserver>
                            </tab-content>
                            <tab-content title="Finish">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="text-left">
                                            <h2 class="mt-0">
                                                <i class="mdi mdi-check-all"></i>
                                            </h2>
                                            <h3 v-if="advertisement.eventType === 'Ongoing'" class="mt-0">{{
                                                this.advertisement.title }} - {{ this.advertisement.eventDateText }}
                                            </h3>
                                            <h3 v-else class="mt-0">{{ this.advertisement.title }} - {{
                                                this.advertisement.eventDateTime | formatLongDate }}</h3>
                                            <p class="mb-4">
                                                {{ this.advertisement.description }}
                                            </p>
                                            <h5 class="mt-0">Advertisement Period</h5>
                                            <hr />
                                            <h5 class="mb-4">Starts: <b>{{ this.advertisement.advertiseStartDate |
                                                    formatLongDate }}</b></h5>
                                            <h5 class="mb-4">Ends: <b>{{ this.advertisement.advertiseEndDate |
                                                    formatLongDate }}</b></h5>

                                            <hr />
                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>

                            <template slot="custom-buttons-left">
                                <b-button @click="cancel" class="mr-2" variant="dark">Cancel</b-button>
                            </template>
                        </form-wizard>
                    </div>
                </div>
            </div>
            <div class="col-xl-2">
                <div class="card" style="width:500px">
                    <div class="card-body">
                        <div class="card img-fluid">
                            <img class="card-img-top" :src="require('@assets/images/iphone.png')" alt="Card image"
                                style="width:100%">
                            <div class="card-img-overlay">
                                <div class="cardAlign">
                                    <div class="card eventCard">
                                        <img class="card-img" :src="this.previewFile" alt="Card image cap">
                                        <div class="card-body">
                                            <p v-if="selectedLocation" class="card-text h4 mt-0">{{
                                                this.selectedLocation.name }}
                                            </p>
                                            <h5 class="card-title text-danger">{{ this.advertisement.title }}</h5>
                                            <p class="card-text" v-if="advertisement.eventType === 'EventDate'">{{
                                                this.advertisement.eventDateTime | formatLongDate }}</p>
                                            <p class="card-text" v-if="advertisement.eventType === 'Ongoing'">{{
                                                this.advertisement.eventDateText }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'

import {
    customMessages
} from '@utils/validationmessage'

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import {
    advertisementData
} from '@state/api/advertisement'

import {
    companyData
} from '@state/api/company'

import {
    locationData
} from '@state/api/location';

import {
    CoreAPIConfig
} from "../../../state/api/config";
import AuthService from "../../../services/AuthService";
const authservice = new AuthService();
import moment from 'moment'

export default {
    page: {
        title: 'Create an Event',
        meta: [{
            name: 'description',
            content: appConfig.description
        }],
    },
    components: {
        Layout,
        PageHeader,
        FormWizard,
        TabContent,
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            title: 'Create an Event',
            locationList: [],
            companyList: [],
            customMessages,
            selectedCompany: undefined,
            selectedLocation: undefined,
            advertisement: {},
            previewFile: require('@assets/images/small/img-1.jpg'),
            productCode: "",
            categories: [{
                id: "00000000-0000-0000-0000-000000000000",
                name: "Category 1"
            }, {
                id: "00000000-0000-0000-0000-000000000001",
                name: "Category 2"
            }],
            startDateTimeConfig: {
                wrap: true, // set wrap to true only when using 'input-group'
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                enableTime: false,
                minDate: new Date()
            },
            eventTimeConfig: {
                noCalendar: true,
                dateFormat: "h:i K",
                wrap: true, // set wrap to true only when using 'input-group'
                enableTime: true,
                time_24hr: false
            },
            advertiseStartDateTimeConfig: {
                wrap: true, // set wrap to true only when using 'input-group'
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                enableTime: false,
                minDate: new Date(),
                time_24hr: false
            },
            advertiseEndDateTimeConfig: {
                wrap: true, // set wrap to true only when using 'input-group'
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                enableTime: false,
                minDate: new Date(),
                time_24hr: false
            },
            dropzoneOptions: {
                url: `${CoreAPIConfig.COREAPI}api/EventAdvertisement/UploadDocument`,
                //maxFilesize: 1000,
                maxFiles: 1,
                autoProcessQueue: true,
                thumbnailHeight: 300,
                thumbnailWidth: 400,
                addRemoveLinks: true,
                acceptedFiles: ".png,.jpg,.jpeg",
                clickable: true,
                dictMaxFilesExceeded: "Only 1 file allowed.",
                resizeWidth: 600,
                resizeMethod: "contain"
            },
            isFileUploaded: false
        }
    },
    watch: {
        selectedCompany: {
            immediate: true,
            deep: true,
            async handler(newValue, oldValue) {
                if (newValue) {
                    await this.loadLocations();
                    this.advertisement.companyKeyyId = newValue.keyId
                }
            }
        },
        selectedLocation: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue) {
                    this.advertisement.locationId = newValue.keyId;
                }
            }
        }
    },
    async created() {
        //await this.loadCompanyProduct(); // disabled for free
        await this.loadCompanies();
    },
    methods: {
        async loadCompanies() {
            this.companyList = await companyData.searchCustomers();
        },
        async loadLocations() {
            if (!this.selectedCompany) {
                return;
            }
            console.log(this.selectedCompany);
            this.locationList = [];
            this.locationList = await locationData.searchLocationByCompany(this.selectedCompany.keyId);
        },
        async validateStep1() {
            let token = await authservice.getSilentToken("core");
            let myHeaders = {
                "Authorization": "Bearer " + token,
                "x-app": "eventspace"
            };
            this.$refs.myVueDropzone.dropzone.options.headers = myHeaders;
            this.advertisement.categoryName = "";
            const success = await this.$refs.step1.validate();
            if (!success) {
                return false;
            }

            if (this.advertisement.eventType === 'EventDate') {
                this.advertisement.eventDateTime = new moment(this.advertisement.eventDate + ' ' + this.advertisement.eventTime).utcOffset(0, true).format();
                this.advertisement.eventDateText = "";
            } else {
                this.advertisement.eventDateTime = null;
            }

            return true;
        },
        async validateStepDiscount() {
            const success = await this.$refs.stepDiscount.validate();
            if (!success) {
                return false;
            }

            return true;
        },
        async validateStep2() {
            let files = this.$refs.myVueDropzone.dropzone.getAcceptedFiles();
            if (files === undefined || files.length <= 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please upload image/media for your advertisement.",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            }

            return true;
        },
        fileUploadFailed(file, message, xhr) {
            this.$vs.notify({
                title: "Failed",
                text: "Media Upload Failed",
                color: "danger",
                position: "top-center"
            });
        },
        fileUploaded(file, response) {
            if (!response.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: response.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.advertisement.fileData = response.data;
            this.$vs.notify({
                title: "Success",
                text: "Media Uploaded",
                color: "success",
                position: "top-center"
            });
        },
        async validateStep3() {

            if (this.advertisement.eventType === 'EventDate' && this.advertisement.eventDateTime <= this.advertisement.advertiseEndDate) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please adjust advetisement end date, end date cannot be later then event date.",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            }

            const success = await this.$refs.step3.validate();
            if (!success) {
                return false;
            }

            let data = await advertisementData.CreateAdvertisement(this.advertisement);
            if (!data.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: data.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.advertisement.id = data.data;

            this.$vs.notify({
                title: "Success",
                text: "Draft Event created, please confirm to proceed.",
                color: "success",
                position: "top-center"
            });

            return true;
        },
        sendingEvent(file, xhr, formData) {
            if (this.advertisement.fileData === undefined) {
                formData.append('id', "");
            } else {
                formData.append('id', this.advertisement.fileData.id);
            }
        },
        maxfilesreached() {
            if (this.$refs.myVueDropzone.dropzone.files.length > 1) {
                this.$vs.notify({
                    title: "Error",
                    text: "Only 1 File is Allowed, file removed",
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
        },
        fileAdded(file) {
            if (this.$refs.myVueDropzone.dropzone.files.length > 1) {
                this.$refs.myVueDropzone.dropzone.removeFile(file);
                this.$vs.notify({
                    title: "Error",
                    text: "Only 1 File is Allowed, file removed",
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.advertisement.eventMediaType = file.type;
            this.advertisement.eventMedia = file.name;
        },
        thumbnailCreated(file, dataUrl) {
            let files = this.$refs.myVueDropzone.dropzone.getUploadingFiles();
            this.previewFile = files[0].dataURL;
        },
        cancel() {
            this.$router.push("/Event/AllActiveEvents");
        },
        async onComplete() {
            this.$vs.notify({
                title: "success",
                text: "Event Created.",
                color: "success",
                position: "top-center"
            });
            this.$router.push("/Event/AllActiveEvents");
        }
    },
}
</script>

<style scoped>
.card {
    border: 1px solid gainsboro;
}

.card .card-img-top {
    width: 50%;
}

.card-img {
    max-height: 300px;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.eventCard {
    margin: 0px;
    border: 0;
}

#img-upload {
    width: 100%;
}

.cardAlign {
    margin: 110px 12px 20px 8px;
    height: 78%;
    /*overflow-y: scroll;*/
}

.card-body {
    border-radius: 0px;
}

.card-img-overlay {
    padding: 2px 1.25rem 0px 1.25rem;
}
</style>
